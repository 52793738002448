import { NgModule } from '@angular/core';

import { bruitConfig } from 'src/app/core/bruit-io/bruit-io.config';
import { BruitIoModule } from 'src/app/core/bruit-io/bruit-io.module';
import { version } from '../../package.json';

@NgModule({
  imports: [BruitIoModule.forRoot(bruitConfig)],
  exports: [BruitIoModule],
})
export class OptionalBruitIoModule {}

export const environment = {
  production: true,
  appVersion: version,
  jwtBearerTokenKey: 'izy_bearer_admin_token',
  backendUrl: 'https://dev-api-izy.factorygroup.fr',
  bruitConfig,
  optionalModules: [OptionalBruitIoModule],
};
