import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFR from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';
import { tokenGetter } from './auth/token-getter';
import { ApiResponse401Interceptor } from './interceptors/api-response-401.interceptor';

const ioModules: any[] = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  ...environment.optionalModules,
  // Snackbar utilisée globalement
  MatSnackBarModule,
];

registerLocaleData(localeFR, 'fr');

@NgModule({
  declarations: [],
  imports: [
    ...ioModules,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.backendUrl.replace(/^https?\:\/\//i, '')],
      },
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiResponse401Interceptor, multi: true },
  ],
  exports: [...ioModules],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() coreModule: CoreModule) {
    if (coreModule) {
      throw new Error('CoreModule should only be imported once in the root module');
    }
  }
}
