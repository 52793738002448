import { InjectionToken } from '@angular/core';
import { BrtConfig } from '@bruit/types';

export const BRUIT_IO_CONFIG = new InjectionToken<BrtConfig>('bruit.io.config');

export const bruitConfig: BrtConfig = {
  apiKey: '21615121-92e3-4c3b-9beb-39e70f89e8a4',
  labels: {
    title: 'Envoyer un feedback',
  },
  form: [
    {
      label: 'Description (comportement rencontré, actions effectuées, ...)',
      type: 'textarea',
      required: true,
    },
    {
      id: 'agreement',
      type: 'checkbox',
      label: `J'accepte d'envoyer des informations techniques en complément de mes réponses`,
      value: true,
    },
  ],
};
