import { Component, Inject, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BrtConfig, BrtData } from '@bruit/types';

import { BRUIT_IO_CONFIG } from '../bruit-io.config';

@Component({
  selector: 'app-bruit-izy',
  templateUrl: './bruit-izy.component.html',
  styleUrls: ['./bruit-izy.component.scss'],
})
export class BruitIzyComponent implements OnInit {
  constructor(@Inject(BRUIT_IO_CONFIG) public bruitConfig: BrtConfig, private jwtHelper: JwtHelperService) {}

  ngOnInit(): void {}

  getBruitData(): BrtData[] {
    const decodedToken: any = this.jwtHelper.decodeToken(this.jwtHelper.tokenGetter());
    return [
      {
        label: 'token',
        value: decodedToken,
      },
    ];
  }

  handleBruitError(error: Event): void {
    console.error('Erreur bruit-io : ', error);
  }
}
