import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { applyPolyfills, defineCustomElements } from '@bruit/component/loader';
import { BrtConfig } from '@bruit/types';
// @ts-ignore: disable-next-line
import BruitCore from '@bruit/component/dist/collection/start';

import { BRUIT_IO_CONFIG } from './bruit-io.config';
import { BruitIzyComponent } from './bruit-izy/bruit-izy.component';

@NgModule({
  declarations: [BruitIzyComponent],
  imports: [MatTooltipModule],
  exports: [BruitIzyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BruitIoModule {
  constructor(@Optional() @SkipSelf() parentModule: BruitIoModule) {
    if (parentModule) {
      throw new Error('BruitIoModule should only be imported once in the root module');
    }
    // Bruit-io setup
    applyPolyfills().then(() => {
      defineCustomElements(window).then(() => {
        BruitCore({
          logCacheLength: {
            log: 999,
            debug: 999,
            info: 999,
            warn: 999,
            error: 999,
            network: 999,
            click: 999,
            url: 999,
          },
        });
      });
    });
  }

  static forRoot(config: BrtConfig): ModuleWithProviders<BruitIoModule> {
    return {
      ngModule: BruitIoModule,
      providers: [{ provide: BRUIT_IO_CONFIG, useValue: config }],
    };
  }
}
