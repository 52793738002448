import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AdminLoginForm } from '@izy-barcode/types';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private snackBar: MatSnackBar) {}

  /**
   * Login service, from site/depot/user/password data
   */
  login(query: AdminLoginForm): Observable<string> {
    return this.http.post<{ token: string }>(`${environment.backendUrl}/api/v1/admin/login`, query).pipe(
      map((res: { token: string }) => {
        localStorage.setItem(environment.jwtBearerTokenKey, res.token);
        return res.token;
      }),
      shareReplay(),
      catchError((err: any) => {
        console.error(err);
        this.snackBar.open(`Erreur d'authentification`, 'OK', { duration: 3000 });
        return throwError(err);
      })
    );
  }

  /**
   * Logout service. Empties token stored in localStorage
   */
  logout(saveLogout: boolean = false): Promise<void> {
    return (saveLogout
      ? this.http.post<void>(`${environment.backendUrl}/api/v1/logout`, {}).toPromise()
      : Promise.resolve()
    ).then(() => {
      localStorage.removeItem(environment.jwtBearerTokenKey);
    });
  }

  /**
   * True if the stored token is still valid
   */
  isLoggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }
}
